import React from 'react'
import {Heading,SimpleGrid,Box,Text} from '@chakra-ui/react'
import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'

import MeinSeo from '../components/seo'

export default function Beitrag5(){

return(
<>
<MeinSeo title="Ferdinand Fries " description='Ferdinand Friess`Beitrag zum Aussstellungsparcours '/>



<Heading ml="3"  variant="Titel">
Der Maler  Ferdinand Friess 
</Heading>
<SimpleGrid p="0" mx="0" mb="10" gap={4} columns={[1,1,2]}>
<Box px="2" mx="0"  pb="2">
                <motion.div
  initial={{opacity:0,scale:.7}}
  animate={{opacity:1,scale:1}}
  transition={{duration:1,delay:.3}}
  >



  </motion.div>
                <Box>
<Text width="95%" variant='solid'>
Der Maler Ferdinand Friess (geb. 1940) hat sich mit seinen großformatigen Bildern und Installationen dem Engagement gegen Geschichtsvergessenheit verschrieben und thematisiert u.a. mit der Verarbeitung von Kindheitserinnerungen die Biographie seiner eigenen Familie, den Militarismus des nazistischen Deutschland und den Umgang mit jüdischem Raubgut. 
</Text>
<Box pb="4" my="0">

  <Text py="6"  width={["100%","98%","98%","100%"]} pb="2" fontWeight="400"  lineHeight="1" fontSize={["3xl","5xl","5xl"]}>"Ich will sichtbar machen, was mich beunruhigt, meinen Augen
 Antwort geben."</Text>
  </Box>
<Text width="95%" variant='solid'>
In der Ausstellung werden zwei Bilder gezeigt, die Reminiszenzen an den Krieg, das Ende der Naziherrschaft und den Widerstand thematisieren. Das Bild „Teddy 1944“ hält Assoziationen von Vernichtung und Befreiung in der Schwebe, während „Mein Großvater und ich“ die Erinnerung an den Pazifismus des Großvaters wachhält.
</Text>
</Box> 
                
                </Box>
           
                <Box  px="2"  py="3">
                    
                    <StaticImage src="../images/fries/teddy.jpg" alt="Teddy, Bild von Ferdinand Fries" height={650}
                    width={450} />
                    
                   
                    <Text textAlign="center" variant='Bild'>Teddy 1944</Text>
                    <StaticImage src="../images/fries/61.jpg" alt="Teddy, Bild von Ferdinand Fries"  width={450} height={650}/>
                   
                   
                   <Text textAlign="center" variant='Bild'>Mein Großvater und ich</Text>
                </Box>
              </SimpleGrid>

        </>
    )
}